


import React, {  useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import store from '../actions/store/store';
import {  Icon, Spinner } from '@blueprintjs/core';
import { Keypads } from '../components/keypads';
import { ScaleLoader } from 'react-spinners';
import { UserAction } from '../actions/urserAction';
import { startOperation } from '../kiosklog/ServerLogging';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ExternalCss } from '../components/ExternalCss';
import Logo from '../components/Logo';
import Button from '../components/Button';
import { firebase } from '../global/firebase';
import { addDoc, collection } from 'firebase/firestore';
import Swal from 'sweetalert2';

export  const Mobile=()=>{
    const location = useLocation()
    const navigate= useNavigate();
    const [businesses,setBusinesses]=useState(null as any)
    const [site,setsite]=useState(null as any)
    const [logo,setlogo]=useState('')
    const [bool,setBool]=useState(false);
    const [opencachepopup,setopencachepopup]=useState(false);
    const [advInArr,setadvInArr]=useState([] as any);
    const [advOutArr,setadvOutArr]=useState([] as any);
    const [areaCode,setareaCode]=useState([
        205, 251, 256, 334, 659, 938,907,	480, 520, 602, 623, 928,479, 501, 870,209, 213, 279, 310, 323, 341, 350, 408, 415, 424, 442, 510, 530,
        559, 562, 619, 626, 628, 650, 657, 661, 669, 707, 714, 747, 760, 805, 818, 820, 831, 840, 858, 909, 916, 925, 949, 951,
        303, 719, 720, 970, 983,203, 475, 860, 959,302,239, 305, 321, 352, 386, 407, 448, 561, 656, 689, 727, 754, 772, 786, 813, 850, 863, 904, 941, 954,
        229, 404, 470, 478, 678, 706, 762, 770, 912, 943,	808,208, 986,217, 224, 309, 312, 331, 447, 464, 618, 630, 708, 773, 779, 815, 847, 872,
        219, 260, 317, 463, 574, 765, 812, 930,	319, 515, 563, 641, 712,316, 620, 785, 913,270, 364, 502, 606, 859,225, 318, 337, 504, 985,
        207,240, 301, 410, 443, 667,	339, 351, 413, 508, 617, 774, 781, 857, 978,231, 248, 269, 313, 517, 586, 616, 734, 810, 906, 947, 989,	218, 320, 507, 612, 651, 763, 952
        ,228, 601, 662, 769,314, 417, 557, 573, 636, 660, 816,	406,	308, 402, 531,702, 725, 775,603,201, 551, 609, 640, 732, 848, 856, 862, 908, 973,
        505, 575,212, 315, 332, 347, 363, 516, 518, 585, 607, 631, 646, 680, 716, 718, 838, 845, 914, 917, 929, 934,252, 336, 472, 704, 743, 828, 910, 919, 980, 984,
        701,216, 220, 234, 326, 330, 380, 419, 440, 513, 567, 614, 740, 937,405, 539, 572, 580, 918,458, 503, 541, 971,	215, 223, 267, 272, 412, 445, 484, 570, 582,
        610, 717, 724, 814, 835, 878,401,803, 839, 843, 854, 864,	605,423, 615, 629, 731, 865, 901, 931,	210, 214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512,
        682, 713, 726, 737, 806, 817, 830, 832, 903, 915, 936, 940, 945, 956, 972, 979,	385, 435, 801,	802,276, 434, 540, 571, 703, 757, 804, 826, 948,
        206, 253, 360, 425, 509, 564,202, 771,304, 681,262, 414, 534, 608, 715, 920,	307,	684,	671,	670,	787, 939,	340
             ])
        const [mobileIsValid,setmobileIsValid]=useState(false)
        const [showToast,setshowToast]=useState(false)
        const [showAdminControls,setshowAdminControls]=useState(false)
        const [mobile,setmobile]=useState('')
        const [continueOkay,setcontinueOkay]=useState(false)
        const[tearApartAll,settearApartAll]=useState(false)
        const[tearApart,settearApart]=useState(false)
        const[tearApartJoinSlc,settearApartJoinSlc]=useState(false)
        const[tearApartJoinOgden,settearApartJoinOgden]=useState(false)
        const[tearApartCheckinOgden,settearApartCheckinOgden]=useState(false)
        const[tearApartVehicleSlc,settearApartVehicleSlc]=useState(false)
        const[tearApartVehicleOgden,settearApartVehicleOgden]=useState(false)
        const[tearApartCheckinSlc,settearApartCheckinSlc]=useState(false)
        const[centralFloridaPickAndPay,setcentralFloridaPickAndPay]=useState(false)

        const [kioskUiVehicleConfig,setkioskUiVehicleConfig]=useState(false)
        const [kioskUiTabletView,setkioskUiTabletView]=useState(false)
        const [kioskUiAllInOneCheckIn,setkioskUiAllInOneCheckIn]=useState(false)
        const [langEnglish,setlangEnglish]=useState(true)
        const [langSpanish,setlangSpanish]=useState(false)
        const [buttonDisabledColor,setbuttonDisabledColor]=useState('rgb(136, 136, 136)')
        const [buttonsucsessColor,setbuttonsucsessColor]=useState('rgb(70, 127, 30)')
        const termconditionCss={    
          color: 'rgb(232, 165, 48)',
          textShadow: 'black 0px 0px 1px',
          fontsize: '10px',
          maxWidth: '400px'
        }

 

        const [theftpreventmsgen,settheftpreventmsgen]=useState('Access Restricted We’re unable to complete your check-in due to a past security-related issue. At this time, access to the facility is not permitted. If you have any questions, please contact management.');
        const [theftpreventmsgsp,settheftpreventmsgsp]=useState('Acceso Restringido No podemos completar su registro debido a un problema de seguridad previo. En este momento, no se permite el acceso a la instalación. Si tiene alguna pregunta, por favor comuníquese con la administración.');
    

    useEffect(()=>{ 

            
        if(location.state===null && businesses===null ){
            const url  = window.location.href.split('/');
            
            const  bPath = url[3];
            const  sPath = url[4];
            store.getState().BusinessesReducer.Businesses.map((b:any)=>{
                if(b.path===bPath){
                  try {             
                    
                  if(b.langEnglish!==undefined){
                    setlangEnglish(b.langEnglish)
                  }
                  if(b.langSpanish!==undefined){
                    setlangSpanish(b.langSpanish)
                  }                
                    
                } catch (error) {
                    console.log(error);
                    
                }
                  
                const el:any =document.querySelector('title') 
                  if(el!==undefined && el!==null){
                    el.innerText = b.businessName+' kiosk'
                  }

                  if(b!==null && b.theme!==undefined && b.theme.background!==undefined &&  (b.theme.background).includes('http')){
                    b.theme.background =  b.theme.background+' center center / cover'
                    }                  
                    setBusinesses(b)

                    try {
                      if(b.theftpreventmsgen!=undefined && theftpreventmsgen!=''){
                        settheftpreventmsgen(b.theftpreventmsgen);
                      }
                      if(b.theftpreventmsgsp!=undefined && b.theftpreventmsgsp!=''){
                        settheftpreventmsgsp(b.theftpreventmsgsp);
                      }
                    } catch (error) {
                      console.log(error);
                      
                    }
                    try {                
                      if (b.theme && b.theme.logo) {
                          setlogo(b.theme.logo)
                      }
                          
                     } catch (error) {
                      console.log(error);                          
                    }
                    b.sites.map((s:any)=>{
                        if(s.path===sPath){   

                          try {
                
                            if (s.theme && s.theme.logo) {
                                setlogo(s.theme.logo)
                            }
                                
                        } catch (error) {
                           console.log(error);
                                
                        }
            

                          if(b!==null && b.theme!==undefined && b.theme.colors!==undefined){

                            if(b.theme.colors.primary!==undefined && b.theme.colors.primary!=='' && (s.kioskUiButtonPrimaryColor===undefined || s.kioskUiButtonPrimaryColor===null || s.kioskUiButtonPrimaryColor==='')){
                              s.kioskUiButtonPrimaryColor = b.theme.colors.primary
                            }

                            if(b.theme.colors.success!==undefined && b.theme.colors.success!=='' && (s.kioskUiButtonSuccessColor===undefined || s.kioskUiButtonSuccessColor===null || s.kioskUiButtonSuccessColor==='')){
                              s.kioskUiButtonSuccessColor = b.theme.colors.success
                            }
                            
                            if(b.theme.colors.error!==undefined && b.theme.colors.error!=='' && (s.kioskUiButtonErrorColor===undefined || s.kioskUiButtonErrorColor===null || s.kioskUiButtonErrorColor==='')){
                              s.kioskUiButtonErrorColor = b.theme.colors.error
                            }                           

                            if(b.theme.colors.success!==undefined && b.theme.colors.success!=='' && (s.kioskUiSigButtonSuccessColor===undefined || s.kioskUiSigButtonSuccessColor===null || s.kioskUiSigButtonSuccessColor==='')){
                              s.kioskUiSigButtonSuccessColor = b.theme.colors.success
                            } 
                            if(b.theme.colors.secondary!==undefined && b.theme.colors.secondary!=='' && (s.kioskUiTitleTextColor===undefined || s.kioskUiTitleTextColor===null || s.kioskUiTitleTextColor==='')){
                              s.kioskUiTitleTextColor = b.theme.colors.secondary
                            } 
                            
                            if(b.theme.colors.secondary!==undefined && b.theme.colors.secondary!=='' && (s.kioskUiSigTitleTextColor===undefined || s.kioskUiSigTitleTextColor===null || s.kioskUiSigTitleTextColor==='')){
                              s.kioskUiSigTitleTextColor = b.theme.colors.secondary                              
                            } 
                            
                              }
                          
                            if(s!==null && s.kioskUiAdvHeight!==undefined && window.innerWidth<=1280){
                              s.kioskUiAdvHeight = 300
                              s.kioskUiAdvWidth = 300
                            }                                                
                            setsite(s)
                            const site =s;
                            console.log(site,'usasa');
                            
                            const  advInArrx = [];
                            if(site.kioskUiInAdvFile1!==undefined && site.kioskUiInAdvFile1!==null && site.kioskUiInAdvFile1!==''){ advInArrx.push(site.kioskUiInAdvFile1) }
                            if(site.kioskUiInAdvFile2!==undefined && site.kioskUiInAdvFile2!==null && site.kioskUiInAdvFile2!==''){ advInArrx.push(site.kioskUiInAdvFile2) }
                            if(site.kioskUiInAdvFile3!==undefined && site.kioskUiInAdvFile3!==null && site.kioskUiInAdvFile3!==''){ advInArrx.push(site.kioskUiInAdvFile3) }
                            if(site.kioskUiInAdvFile4!==undefined && site.kioskUiInAdvFile4!==null && site.kioskUiInAdvFile4!==''){ advInArrx.push(site.kioskUiInAdvFile4)}
                            if(site.kioskUiInAdvFile5!==undefined && site.kioskUiInAdvFile5!==null && site.kioskUiInAdvFile5!==''){ advInArrx.push(site.kioskUiInAdvFile5)}
                            if(site.kioskUiInAdvFile6!==undefined && site.kioskUiInAdvFile6!==null && site.kioskUiInAdvFile6!==''){ advInArrx.push(site.kioskUiInAdvFile6)}
                            if(site.kioskUiInAdvFile7!==undefined && site.kioskUiInAdvFile7!==null && site.kioskUiInAdvFile7!==''){ advInArrx.push(site.kioskUiInAdvFile7)}
                            if(site.kioskUiInAdvFile8!==undefined && site.kioskUiInAdvFile8!==null && site.kioskUiInAdvFile8!==''){ advInArrx.push(site.kioskUiInAdvFile8)}
                            if(site.kioskUiInAdvFile9!==undefined && site.kioskUiInAdvFile9!==null && site.kioskUiInAdvFile9!==''){ advInArrx.push(site.kioskUiInAdvFile9)}
                            if(site.kioskUiInAdvFile10!==undefined && site.kioskUiInAdvFile10!==null && site.kioskUiInAdvFile10!==''){ advInArrx.push(site.kioskUiInAdvFile10)}
                            if(advInArr.length<1){
                            setadvInArr(advInArrx)
                            }
                  
                            const  advOutArrx = [];
                            if(site.kioskUiOutAdvFile1!==undefined && site.kioskUiOutAdvFile1!==null && site.kioskUiOutAdvFile1!==''){ advOutArrx.push(site.kioskUiOutAdvFile1) }
                            if(site.kioskUiOutAdvFile2!==undefined && site.kioskUiOutAdvFile2!==null && site.kioskUiOutAdvFile2!==''){ advOutArrx.push(site.kioskUiOutAdvFile2) }
                            if(site.kioskUiOutAdvFile3!==undefined && site.kioskUiOutAdvFile3!==null && site.kioskUiOutAdvFile3!==''){ advOutArrx.push(site.kioskUiOutAdvFile3) }
                            if(site.kioskUiOutAdvFile4!==undefined && site.kioskUiOutAdvFile4!==null && site.kioskUiOutAdvFile4!==''){ advOutArrx.push(site.kioskUiOutAdvFile4)}
                            if(site.kioskUiOutAdvFile5!==undefined && site.kioskUiOutAdvFile5!==null && site.kioskUiOutAdvFile5!==''){ advOutArrx.push(site.kioskUiOutAdvFile5)}
                            if(site.kioskUiOutAdvFile6!==undefined && site.kioskUiOutAdvFile6!==null && site.kioskUiOutAdvFile6!==''){ advOutArrx.push(site.kioskUiOutAdvFile6)}
                            if(site.kioskUiOutAdvFile7!==undefined && site.kioskUiOutAdvFile7!==null && site.kioskUiOutAdvFile7!==''){ advOutArrx.push(site.kioskUiOutAdvFile7)}
                            if(site.kioskUiOutAdvFile8!==undefined && site.kioskUiOutAdvFile8!==null && site.kioskUiOutAdvFile8!==''){ advOutArrx.push(site.kioskUiOutAdvFile8)}
                            if(site.kioskUiOutAdvFile9!==undefined && site.kioskUiOutAdvFile9!==null && site.kioskUiOutAdvFile9!==''){ advOutArrx.push(site.kioskUiOutAdvFile9)}
                            if(site.kioskUiOutAdvFile10!==undefined && site.kioskUiOutAdvFile10!==null && site.kioskUiOutAdvFile10!==''){ advOutArrx.push(site.kioskUiOutAdvFile10)}
                            if(advOutArr.length<1){
                            setadvOutArr(advOutArrx)
                            }
                            const siteId =site.siteId!==undefined ? site.siteId :''
                            const businessId =b.businessId!==undefined ? b.businessId :''
                            const centralFloridaPickAndPay = (siteId && siteId === 'R7JASbt9Okbw8qlPfrS4' && businessId && businessId === 'zDH9H7YbQ0roTbjW23gM') ? true : false;
                            const tearApartJoinSlc = (siteId && siteId === 'FDYHKAMSiYNCuXumk5Iv' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') || window.location.href.includes('join-salt-lake-city')? true : false;
                            const tearApartJoinOgden = (siteId && siteId === 'TMrSaHl6j451pEWxY7nu' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW')  || window.location.href.includes('join-ogden') ? true : false;
                            const tearApartCheckinSlc = (siteId && siteId === 'kv9ciglhFsJBh9YyV2sd' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') || window.location.href.includes('check-in-salt-lake-city')? true : false;
                            const tearApartCheckinOgden = (siteId && siteId === '6vVH4qpzsVxjmROhMDmh' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') || window.location.href.includes('check-in-ogden') ? true : false;
                            const tearApartVehicleSlc = (siteId && siteId === '17VBJyeH2crmqQC2OSxx' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') ? true : false;
                            const tearApartVehicleOgden = (siteId && siteId === '1YYyeZ1YXjGyc6Z6vwRt' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') ? true : false;
                            const tearApart = ((siteId && siteId === 'bsZLKWJMaoIwztWi0gMg' && businessId && businessId === 'WpJtEW5saD7d4bRutXXb') || (siteId && siteId === 'JGOUaPdjCw7AxY1auyYy' && businessId && businessId === 'SmhXMTflviq60mlf221Z')) ? true : false;
                            const tearApartAll = tearApart 
                                                || tearApartCheckinOgden
                                                || tearApartCheckinSlc
                                                || tearApartJoinOgden
                                                || tearApartJoinSlc
                                                || tearApartVehicleOgden
                                                || tearApartVehicleSlc || window.location.href.includes('tear-a-part'); 
                            if(site.kioskUiVehicleConfig!==undefined){ setkioskUiVehicleConfig(site.kioskUiVehicleConfig) }
                            if(site.kioskUiTabletView!==undefined){ setkioskUiTabletView(site.kioskUiTabletView) }
                            if(site.kioskUiAllInOneCheckIn!==undefined){ setkioskUiAllInOneCheckIn(site.kioskUiAllInOneCheckIn) }
                            settearApartAll(tearApartAll)
                            setcentralFloridaPickAndPay(centralFloridaPickAndPay)

                            settearApart(tearApart)
                            settearApartJoinSlc(tearApartJoinSlc)
                            settearApartJoinOgden(tearApartJoinOgden)
                            settearApartCheckinOgden(tearApartCheckinOgden)
                            settearApartVehicleSlc(tearApartVehicleSlc)
                            settearApartVehicleOgden(tearApartVehicleOgden)
                            settearApartCheckinSlc(tearApartCheckinSlc)
                            if(site.kioskUiButtonDisabledColor!==undefined && site.kioskUiButtonDisabledColor!==''){   setbuttonDisabledColor(site.kioskUiButtonDisabledColor) }
                            if(site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==''){   setbuttonsucsessColor(site.kioskUiButtonSuccessColor) }
                            
                        }
                    });



                }
            }) 
        
            setBool(!bool)    


        }else if(location.state!==null){
          const  advInArrx = [];
          const site = location.state.site;
          if(site.kioskUiInAdvFile1!==undefined && site.kioskUiInAdvFile1!==null && site.kioskUiInAdvFile1!==''){ advInArrx.push(site.kioskUiInAdvFile1) }
          if(site.kioskUiInAdvFile2!==undefined && site.kioskUiInAdvFile2!==null && site.kioskUiInAdvFile2!==''){ advInArrx.push(site.kioskUiInAdvFile2) }
          if(site.kioskUiInAdvFile3!==undefined && site.kioskUiInAdvFile3!==null && site.kioskUiInAdvFile3!==''){ advInArrx.push(site.kioskUiInAdvFile3) }
          if(site.kioskUiInAdvFile4!==undefined && site.kioskUiInAdvFile4!==null && site.kioskUiInAdvFile4!==''){ advInArrx.push(site.kioskUiInAdvFile4)}
          if(site.kioskUiInAdvFile5!==undefined && site.kioskUiInAdvFile5!==null && site.kioskUiInAdvFile5!==''){ advInArrx.push(site.kioskUiInAdvFile5)}
          if(site.kioskUiInAdvFile6!==undefined && site.kioskUiInAdvFile6!==null && site.kioskUiInAdvFile6!==''){ advInArrx.push(site.kioskUiInAdvFile6)}
          if(site.kioskUiInAdvFile7!==undefined && site.kioskUiInAdvFile7!==null && site.kioskUiInAdvFile7!==''){ advInArrx.push(site.kioskUiInAdvFile7)}
          if(site.kioskUiInAdvFile8!==undefined && site.kioskUiInAdvFile8!==null && site.kioskUiInAdvFile8!==''){ advInArrx.push(site.kioskUiInAdvFile8)}
          if(site.kioskUiInAdvFile9!==undefined && site.kioskUiInAdvFile9!==null && site.kioskUiInAdvFile9!==''){ advInArrx.push(site.kioskUiInAdvFile9)}
          if(site.kioskUiInAdvFile10!==undefined && site.kioskUiInAdvFile10!==null && site.kioskUiInAdvFile10!==''){ advInArrx.push(site.kioskUiInAdvFile10)}
          if(advInArr.length<1){
          setadvInArr(advInArrx)
          }

          const  advOutArrx = [];
          if(site.kioskUiOutAdvFile1!==undefined && site.kioskUiOutAdvFile1!==null && site.kioskUiOutAdvFile1!==''){ advOutArrx.push(site.kioskUiOutAdvFile1) }
          if(site.kioskUiOutAdvFile2!==undefined && site.kioskUiOutAdvFile2!==null && site.kioskUiOutAdvFile2!==''){ advOutArrx.push(site.kioskUiOutAdvFile2) }
          if(site.kioskUiOutAdvFile3!==undefined && site.kioskUiOutAdvFile3!==null && site.kioskUiOutAdvFile3!==''){ advOutArrx.push(site.kioskUiOutAdvFile3) }
          if(site.kioskUiOutAdvFile4!==undefined && site.kioskUiOutAdvFile4!==null && site.kioskUiOutAdvFile4!==''){ advOutArrx.push(site.kioskUiOutAdvFile4)}
          if(site.kioskUiOutAdvFile5!==undefined && site.kioskUiOutAdvFile5!==null && site.kioskUiOutAdvFile5!==''){ advOutArrx.push(site.kioskUiOutAdvFile5)}
          if(site.kioskUiOutAdvFile6!==undefined && site.kioskUiOutAdvFile6!==null && site.kioskUiOutAdvFile6!==''){ advOutArrx.push(site.kioskUiOutAdvFile6)}
          if(site.kioskUiOutAdvFile7!==undefined && site.kioskUiOutAdvFile7!==null && site.kioskUiOutAdvFile7!==''){ advOutArrx.push(site.kioskUiOutAdvFile7)}
          if(site.kioskUiOutAdvFile8!==undefined && site.kioskUiOutAdvFile8!==null && site.kioskUiOutAdvFile8!==''){ advOutArrx.push(site.kioskUiOutAdvFile8)}
          if(site.kioskUiOutAdvFile9!==undefined && site.kioskUiOutAdvFile9!==null && site.kioskUiOutAdvFile9!==''){ advOutArrx.push(site.kioskUiOutAdvFile9)}
          if(site.kioskUiOutAdvFile10!==undefined && site.kioskUiOutAdvFile10!==null && site.kioskUiOutAdvFile10!==''){ advOutArrx.push(site.kioskUiOutAdvFile10)}
          if(advOutArr.length<1){
          setadvOutArr(advOutArrx)
          }
          
  
          const b =location.state.business
          const s = location.state.site
          try{
            if(b.langEnglish!==undefined){
              setlangEnglish(b.langEnglish)
            }
            if(b.langSpanish!==undefined){
              setlangSpanish(b.langSpanish)
            }  

            const el:any =document.querySelector('title') 
            if(el!==undefined && el!==null){
              el.innerText = b.businessName+' kiosk'
            }

            } catch (error) {
                console.log(error);
                
            }        
    
          if(b!==null && b.theme!==undefined && b.theme.background!==undefined &&  (b.theme.background).includes('http')){
              if(b.theme.background.includes('center center')){
                b.theme.background =  b.theme.background
              }else{
                b.theme.background =  b.theme.background+' center center / cover'
              }
          } 
      
             
          setBusinesses(b)  
          try {                
            if (b.theme && b.theme.logo) {
                setlogo(b.theme.logo)
            }
                
           } catch (error) {
            console.log(error);                          
          }  

          if(b!==null && b.theme!==undefined && b.theme.colors!==undefined){
            if(b.theme.colors.primary!==undefined && b.theme.colors.primary!=='' && (s.kioskUiButtonPrimaryColor===undefined || s.kioskUiButtonPrimaryColor===null || s.kioskUiButtonPrimaryColor==='')){
              s.kioskUiButtonPrimaryColor = b.theme.colors.primary
            }

            if(b.theme.colors.success!==undefined && b.theme.colors.success!=='' && (s.kioskUiButtonSuccessColor===undefined || s.kioskUiButtonSuccessColor===null || s.kioskUiButtonSuccessColor==='')){
              s.kioskUiButtonSuccessColor = b.theme.colors.success
            }
            
            if(b.theme.colors.error!==undefined && b.theme.colors.error!=='' && (s.kioskUiButtonErrorColor===undefined || s.kioskUiButtonErrorColor===null || s.kioskUiButtonErrorColor==='')){
              s.kioskUiButtonErrorColor = b.theme.colors.error
            }                           

            if(b.theme.colors.success!==undefined && b.theme.colors.success!=='' && (s.kioskUiSigButtonSuccessColor===undefined || s.kioskUiSigButtonSuccessColor===null || s.kioskUiSigButtonSuccessColor==='')){
              s.kioskUiSigButtonSuccessColor = b.theme.colors.success
            } 
            if(b.theme.colors.secondary!==undefined && b.theme.colors.secondary!=='' && (s.kioskUiTitleTextColor===undefined || s.kioskUiTitleTextColor===null || s.kioskUiTitleTextColor==='')){
              s.kioskUiTitleTextColor = b.theme.colors.secondary
            }                           
            if(b.theme.colors.secondary!==undefined && b.theme.colors.secondary!=='' && (s.kioskUiSigTitleTextColor===undefined || s.kioskUiSigTitleTextColor===null || s.kioskUiSigTitleTextColor==='')){
              s.kioskUiSigTitleTextColor = b.theme.colors.secondary
            } 
            
              }

              
              if(s!==null && s.kioskUiAdvHeight!==undefined && window.innerWidth<=1280){
                s.kioskUiAdvHeight = 300
                s.kioskUiAdvWidth = 300
              }  
          setsite(s)

          try {
                
            if (s.theme && s.theme.logo) {
                setlogo(s.theme.logo)
            }
                
        } catch (error) {
           console.log(error);
                
        }

          const siteId =location.state.site.siteId!==undefined ? location.state.site.siteId :''
          const businessId =location.state.business!==undefined ? location.state.business :''
          const centralFloridaPickAndPay = (siteId && siteId === 'R7JASbt9Okbw8qlPfrS4' && businessId && businessId === 'zDH9H7YbQ0roTbjW23gM') ? true : false;
          const tearApartJoinSlc = (siteId && siteId === 'FDYHKAMSiYNCuXumk5Iv' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') || window.location.href.includes('join-salt-lake-city')? true : false;
          const tearApartJoinOgden = (siteId && siteId === 'TMrSaHl6j451pEWxY7nu' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW')  || window.location.href.includes('join-ogden') ? true : false;
          const tearApartCheckinSlc = (siteId && siteId === 'kv9ciglhFsJBh9YyV2sd' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') || window.location.href.includes('check-in-salt-lake-city')? true : false;
          const tearApartCheckinOgden = (siteId && siteId === '6vVH4qpzsVxjmROhMDmh' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') || window.location.href.includes('check-in-ogden') ? true : false;
          const tearApartVehicleSlc = (siteId && siteId === '17VBJyeH2crmqQC2OSxx' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') ? true : false;
          const tearApartVehicleOgden = (siteId && siteId === '1YYyeZ1YXjGyc6Z6vwRt' && businessId && businessId === 'gBHr1GdJSlpASgUvkoLW') ? true : false;
          const tearApart = ((siteId && siteId === 'bsZLKWJMaoIwztWi0gMg' && businessId && businessId === 'WpJtEW5saD7d4bRutXXb') || (siteId && siteId === 'JGOUaPdjCw7AxY1auyYy' && businessId && businessId === 'SmhXMTflviq60mlf221Z')) ? true : false;
          const tearApartAll = tearApart 
                              || tearApartCheckinOgden
                              || tearApartCheckinSlc
                              || tearApartJoinOgden
                              || tearApartJoinSlc
                              || tearApartVehicleOgden
                              || tearApartVehicleSlc; 
          settearApartAll(tearApartAll)
          if(site.kioskUiVehicleConfig!==undefined){ setkioskUiVehicleConfig(site.kioskUiVehicleConfig) }
          if(site.kioskUiTabletView!==undefined){ setkioskUiTabletView(site.kioskUiTabletView) }
          if(site.kioskUiAllInOneCheckIn!==undefined){ setkioskUiAllInOneCheckIn(site.kioskUiAllInOneCheckIn) }

          
          settearApart(tearApart)
          settearApartJoinSlc(tearApartJoinSlc)
          settearApartJoinOgden(tearApartJoinOgden)
          settearApartCheckinOgden(tearApartCheckinOgden)
          settearApartVehicleSlc(tearApartVehicleSlc)
          settearApartVehicleOgden(tearApartVehicleOgden)
          settearApartCheckinSlc(tearApartCheckinSlc)
        }

      
    },[bool])
const  test_same_digit=(num:any)=> {
    const first = num % 10;
    while (num) {
      if (num % 10 !== first){ return false};
  num = Math.floor(num / 10);
    }
    return true
  }
const  keypadValidator = (mobile: string, value: string) => {
    let newMobile: string;
    if (mobile.length < 10 && value.match(/[0-9]/)) {
      newMobile = mobile + value;
      const invalidNXX = [912,913,914,915,916,700,950,958,959]
      if (newMobile.length === 10) {
        
        if(site.mobileValidation!==undefined && site.mobileValidation===true){

        const NPA = parseInt(newMobile.substring(0,3),10);
        const NXX = parseInt(newMobile.substring(3,6),10);
        const xxxx = parseInt(newMobile.substring(6,10),10);
        const last7 = parseInt(newMobile.substring(3,10),10);

        if(parseInt(NXX.toString().substring(1,3),10) === 11 && NXX >= 211 && NXX <= 911){
          setmobileIsValid(false)
          setshowToast(true)
        }else if(NXX===555 && xxxx>=100 && xxxx<=199){
            setmobileIsValid(false)
            setshowToast(true)
        }else if(test_same_digit(last7)){
            setmobileIsValid(false)
            setshowToast(true)
          console.log('last 7 same');
          
        }else if(areaCode.includes(NPA) && !invalidNXX.includes(NXX) ){
          console.log('valid');
          setmobileIsValid(true)
          setshowToast(false)

        }else{
            setmobileIsValid(false)
            setshowToast(true)
        }

      }else{
        setmobileIsValid(true)
        setshowToast(false)
      }}
      return newMobile;
    }
    if (value === "clear") {
        setmobileIsValid(false)
        setshowToast(false)
      return "";
    }
    if (value === "backspace") {
      newMobile = mobile.slice(0, -1);
      setmobileIsValid(false)
    if(newMobile.length<=0){
        setshowToast(false)
    }
      return newMobile;
    }
    return mobile;
  };

  const handleKeypadButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;
    const mobiles = keypadValidator(mobile, value);
    setshowAdminControls( mobiles === "0000" )
    setmobile(mobiles)
  };

  const prettyMobile = (mobile:any) =>
  `(${mobile.substring(0, 3)})${mobile.substring(3, 6)}-${mobile.substring(
    6,
    10,
  )}`;

const showAlert = (lang:any) => {
    Swal.fire({
      title: 'Alert!',
      text:lang=='en' ? theftpreventmsgen:theftpreventmsgsp,
      icon: 'info',
      confirmButtonText: 'OK',
      confirmButtonColor:tearApart || tearApartAll ? "#FFA500"  : !mobileIsValid || continueOkay ? buttonDisabledColor :buttonsucsessColor,
    }).then(()=>{
   
      setcontinueOkay(false)
      setmobileIsValid(false)
      setmobile('');
    });
  };


  const continueHandle=async(lan:any,optOut:boolean,opt:any)=>{
    setcontinueOkay(true)

    if(opt!==null && opt==='opt'){

      const returnvalue =   await startOperation(businesses.businessId,site.siteId);    
      if(returnvalue){
          navigate('name',{state:{mobile:'',user:false,business:businesses,site,language:lan,optOut,IsUpdating:false}})
      }

    }else{

      const response = await  UserAction('mobileContinue',{mobile,businessId:businesses.businessId,siteId:site.siteId}) 
      console.log(response);
    if((response.markForTheft!==undefined && response.markForTheft) && (site.kioskHideTheftAlter!==undefined && site.kioskHideTheftAlter==false)){

      try {

          showAlert(lan)
    
      } catch (error) {
        console.log(error);
        
      }
     
    }else{
      const returnvalue =   await startOperation(businesses.businessId,site.siteId);    
      if(returnvalue){
          navigate('name',{state:{mobile,user:response,business:businesses,site,language:lan,optOut,IsUpdating:false}})
      }
    }

    }
   
  }
  const Telefono = langEnglish===true && langSpanish===false ? 'Telephone Number' : 'Numero Telefonico:';

  const openDashboard=()=>{
    window.location.href = 'https://just-in.texnrewards.com/Check-In-History';
  }

  const clearcache=async()=>{
    try {

    const data = { path:window.location.toString(),timeStamp:new Date()}
    await addDoc(collection(firebase, "CacheClearLog"), data); 
          
  } catch (error) {
      console.log(error,'error');
      
  }
    navigate('');
    window.location.reload();
  }

    return(
       businesses!==null && site!==null ? <div className='main-container1'  
      style={{height:'100vh',background:site.kioskUiBackgroundColor!==undefined && site.kioskUiBackgroundColor!==null && site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor: businesses.theme.background}}>
        <div>
          {showAdminControls ?
          <div className='admincontrols'>
<Button style={{background:'linear-gradient(45deg,#46C9E5 0%,#D26AC2 100%)'}} onClick={()=>clearcache()}> clear cache and reload.</Button><br/>
<Button  style={{background:'linear-gradient(45deg,#46C9E5 0%,#D26AC2 100%)'}} onClick={()=>openDashboard()}> Go To Dashboard </Button>
          </div>
          :''}
        <ExternalCss/>
        {showToast===true ?  
        <div className='toastimp'>
        <p>&#x26A0; Invalid phone number</p>
        <p>&#x26A0; Numero de telefono invalido</p>
        </div>:''}
        
            <div>
<div className='centerDiv2'>
          {site!==undefined && (site.kioskUiHideCity===undefined || site.kioskUiHideCity===false) ?     <p className='enterMobilesub1' style={{position:'absolute',right:'7px',top:'3px',color:site.kioskUiTextColor!==undefined && site.kioskUiTextColor!==null && site.kioskUiTextColor!=='' ? site.kioskUiTextColor :'rgb(252, 173, 20)'}}>
                {site!==undefined && site!==null && site.path!==undefined ? site.path:''}
              </p>:''}
             {tearApart || tearApartAll || kioskUiTabletView || kioskUiVehicleConfig || kioskUiAllInOneCheckIn?   
             
             <div style={{display:'flex',justifyContent:'center'}}>
              <span className='enterMobile logolefttext' style={{textShadow:'unset',width:'30%',color:site.kioskUiTitleTextColor ? site.kioskUiTitleTextColor : businesses.theme!==undefined && businesses.theme.colors!==undefined ?  businesses.theme.colors.secondary: '',fontSize:"40px",display : "flex" ,maxHeight:"12vh", alignItems: "center",justifyContent: "end", height:site.kioskUiLogoHeight, paddingTop:tearApartAll ||  kioskUiVehicleConfig || kioskUiTabletView ?"unset":"3%"}}>
              <b>Welcome to the</b>&nbsp;
              </span>
              <Logo
              border={site.kioskUiLogoBorderEnable!==undefined &&  site.kioskUiLogoBorderEnable  ? site.kioskUiLogoBorderEnable : false}
              borderColor={site.kioskUiLogoBorderColor!==undefined ? site.kioskUiLogoBorderColor : businesses.theme.colors.secondary}
              logo={logo}
              maxHeight="12vh"
              kioskUiLogoWidth={site.kioskUiLogoWidth!==undefined && site.kioskUiLogoWidth!=='' ? site.kioskUiLogoWidth  :''}
              kioskUiLogoHeight={site.kioskUiLogoHeight!==undefined && site.kioskUiLogoHeight!=='' ? site.kioskUiLogoHeight  :''}
            />
            <span className='enterMobile logorighttext' style={{textShadow:'unset',width:'30%',color:site.kioskUiTitleTextColor ? site.kioskUiTitleTextColor : businesses.theme!==undefined && businesses.theme.colors!==undefined ?  businesses.theme.colors.secondary: '',fontSize:"40px",display : "flex" ,maxHeight:"12vh", alignItems: "center",justifyContent: "start", height:site.kioskUiLogoHeight, paddingTop:tearApartAll ||  kioskUiVehicleConfig || kioskUiTabletView ?"unset":"3%"}}>
            &nbsp;{
                  tearApartVehicleOgden || tearApartVehicleSlc || kioskUiVehicleConfig?
                  (<b>Just In Vehicle Alerts!</b>):
                  (<b>VIP Club!</b>)
                }
              </span>
             </div>             
             :
            <Logo
              border={site.kioskUiLogoBorderEnable!==undefined &&  site.kioskUiLogoBorderEnable  ? site.kioskUiLogoBorderEnable : false}
              borderColor={site.kioskUiLogoBorderColor!==undefined ? site.kioskUiLogoBorderColor : businesses.theme.colors.secondary}
              logo={logo}
              maxHeight="12vh"
              kioskUiLogoWidth={site.kioskUiLogoWidth!==undefined && site.kioskUiLogoWidth!=='' ? site.kioskUiLogoWidth  :''}
              kioskUiLogoHeight={site.kioskUiLogoHeight!==undefined && site.kioskUiLogoHeight!=='' ? site.kioskUiLogoHeight  :''}
            />

                    }
            </div>
            <div style={{display:'grid'}}> 
        <span className='enterMobile' style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!==''? site.kioskUiTitleTextColor : 'rgb(252, 173, 20)' ,paddingTop:kioskUiTabletView ? 'unset':''}}>
            {tearApart || tearApartCheckinOgden || tearApartCheckinSlc? "Enter Your Phone Number to Check-In:" : ""}
            {tearApartJoinOgden || tearApartJoinSlc ? "Enter Your Phone Number to Join:" : ""}
            {(!tearApartAll && !kioskUiTabletView && !kioskUiVehicleConfig && !kioskUiAllInOneCheckIn) ? "Enter Your Phone Number:" : ""}
            { tearApartVehicleOgden || tearApartVehicleSlc || kioskUiVehicleConfig ? "Enter Your Mobile Phone Number To Add Vehicles:" : ""}
            { kioskUiTabletView || kioskUiAllInOneCheckIn? "Enter Your Phone Number to Check-In:" : ""}
        </span>

        { (langEnglish===true && langSpanish===false ) || ( langEnglish===false && langSpanish===true) ? '' : 
        <span className='enterMobilesub' style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!==''? site.kioskUiTitleTextColor :'rgb(252, 173, 20)',paddingTop:kioskUiTabletView ? 'unset':'',margin:window.innerWidth>1280? '7px 0px 0px 0px':'0px 0px 0px 0px'}}>
               {tearApart  || tearApartCheckinOgden || tearApartCheckinSlc ? "Introduce tu Numero de Telefono para hacer el Check-In:" : ""}
              {tearApartJoinOgden || tearApartJoinSlc ? "Introduce tu Numero de Telfono para hacer el Join:" : ""}
              {!tearApartAll && !kioskUiTabletView && !kioskUiVehicleConfig && !kioskUiAllInOneCheckIn ? Telefono : ""}
              { tearApartVehicleOgden || tearApartVehicleSlc || kioskUiVehicleConfig ? "Introduzca su número de teléfono móvil para añadir vehículos:" : ""}
              { kioskUiTabletView || kioskUiAllInOneCheckIn? "Introduce tu Numero de Telefono para hacer el Check-In:" : ""}
        </span>
       }

       {/* <span className='enterMobilesub' style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>
       {(!tearApartAll && !kioskUiTabletView && !kioskUiVehicleConfig && !kioskUiAllInOneCheckIn) ? Telefono : ""}</span> */}
           
       </div></div>
       <div className='centerDiv' style={{display:'grid'}}>   
                <div className="css-1rarh3x">
                    <input type="tel" placeholder="(xxx)xxx-xxxx" readOnly={true} value={mobile === "" ? "" : prettyMobile(mobile)} className="css-5yk6n" />
                    <button disabled={continueOkay} value="backspace" className="css-1ceqh3h" onClick={(e:any)=>handleKeypadButton(e)} style={{background:site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>
                    <svg width="2rem" height="2rem" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>
                    </button>                
                </div>

              
                {(site.allowOptOut!==undefined && site.allowOptOut===true && !tearApart && !tearApartCheckinOgden && !tearApartCheckinSlc && !kioskUiTabletView) ? <div className='mt-1'>
                  {businesses.langEnglish  ?   <button onClick={()=>continueHandle('en',true,'opt')} disabled={continueOkay} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : 'rgb(226, 50, 41)'}} className='optoutbtn' >{continueOkay ? <ScaleLoader  width={10} height={15} color="#fff" /> :'Opt Out'}</button>:''}&nbsp;
                  {businesses.langSpanish ?   <button onClick={()=>continueHandle('esp',true,'opt')}  disabled={continueOkay}   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!==''  ? site.kioskUiButtonErrorColor : 'rgb(226, 50, 41)'}} className='optoutbtn'  >{continueOkay ? <ScaleLoader  width={10} height={15} color="#fff" /> :'Optar Por No'}</button>:''}
                </div>
                : site.allowOptOut!==undefined && site.allowOptOut===true && (tearApart || tearApartCheckinOgden || tearApartCheckinSlc || kioskUiTabletView) ?
                <div style={{display:'grid',position: "absolute",top:"40%", left:"63%",marginTop:'10px'}}>
                {businesses.langEnglish  ?  <button className='optoutbtnr'  onClick={()=>continueHandle('en',true,'opt')} disabled={continueOkay} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : 'rgb(226, 50, 41)',fontSize:'1.5em',marginBottom:'4px'}} >{continueOkay ? <ScaleLoader  width={10} height={15} color="#fff" /> : (tearApart || tearApartCheckinOgden || tearApartCheckinSlc || kioskUiTabletView) ? 'Opt Out / Skip To Waiver' : 'Opt Out' }</button>:''}
                  {businesses.langSpanish ?  <button className='optoutbtnr'  onClick={()=>continueHandle('esp',true,'opt')}  disabled={continueOkay}   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!==''  ? site.kioskUiButtonErrorColor : 'rgb(226, 50, 41)' ,fontSize:'17px'}}>{continueOkay ? <ScaleLoader  width={10} height={15} color="#fff" /> : (tearApart || tearApartCheckinOgden || tearApartCheckinSlc || kioskUiTabletView) ? 'Optar Por No - Saltar a la Renuncia' : 'Optar Por No' }</button>:''}
               </div>
                :''
                }
                

              {site.kioskUiHideAdvIn!==undefined && site.kioskUiHideAdvIn===false?  <div className='kioskUiInAdvFile' 
                          style={{                            
                            // marginTop:site.kioskUiAdvTop!==undefined && site.kioskUiAdvTop!=='' ? site.kioskUiAdvTop: '',
                          }}>
                      <Carousel  showThumbs={false} interval={site.kioskUiAdvDelaySec ? Number(site.kioskUiAdvDelaySec+'000') : 7000} showIndicators={false} showArrows={false} autoPlay={true} showStatus={false} stopOnHover={false} infiniteLoop={true}>              
                      {advInArr.map((c:any,i:any)=>(
                          <div key={i}><img style={                            
                            {height:site.kioskUiAdvHeight!==undefined && site.kioskUiAdvHeight!=='' ? site.kioskUiAdvHeight: '',
                            width:site.kioskUiAdvWidth!==undefined && site.kioskUiAdvWidth!=='' ? site.kioskUiAdvWidth: '',   
                            borderRadius:'10px'              
                            }
                          } src={c}/></div>
                         ))}
                     </Carousel>
              </div>:''}
                <Keypads disable={continueOkay} type="mobile" handleKeypadButton={(e:any)=>handleKeypadButton(e)} business={businesses} site={site}/>

              {site.kioskUiHideAdvOut!==undefined && site.kioskUiHideAdvOut===false ?
                <div className='kioskUiOutAdvFile'  style={{                
                  // marginTop:site.kioskUiAdvTop!==undefined && site.kioskUiAdvTop!=='' ? site.kioskUiAdvTop: '',
                }}>
                      <Carousel  showThumbs={false} interval={site.kioskUiAdvDelaySec ? Number(site.kioskUiAdvDelaySec+'000') : 7000} showIndicators={false} showArrows={false}  autoPlay={true}  showStatus={false} stopOnHover={false} infiniteLoop={true}>              
                         {advOutArr.map((c:any,x:any)=>(
                          <div key={x}                         
                          >                           
                            <img style={
                            {height:site.kioskUiAdvHeight!==undefined && site.kioskUiAdvHeight!=='' ? site.kioskUiAdvHeight: '',
                            width:site.kioskUiAdvWidth!==undefined && site.kioskUiAdvWidth!=='' ? site.kioskUiAdvWidth: '', 
                            borderRadius:'10px'                          
                            }
                          } src={c}/></div>
                         ))}

                     </Carousel>
              </div>:''}

             {businesses.langEnglish   ? <div className={businesses.langSpanish  ? 'continuecontainerleft':'mt-20'}>
                        <Button
                        size={'2rem'}
                        disableColor = {site.kioskUiButtonDisabledColor!==undefined && site.kioskUiButtonDisabledColor!==null && site.kioskUiButtonDisabledColor!=='' ? site.kioskUiButtonDisabledColor : 'rgb(136, 136, 136)'}
                        disabled={!mobileIsValid || continueOkay} className='continuebtn' 
                        bg={tearApart || tearApartAll ? "#FFA500"  : !mobileIsValid || continueOkay ? buttonDisabledColor :buttonsucsessColor}
                        color={tearApart || tearApartAll ? "#45444A" : site.kioskUiContinueBtnTextColor!==undefined && site.kioskUiContinueBtnTextColor!==null && site.kioskUiContinueBtnTextColor!=='' ? site.kioskUiContinueBtnTextColor : 'white'}
                        style={{display:site.kioskUiHideContBtn!==undefined && site.kioskUiHideContBtn===true && !mobileIsValid ? 'none' :'block' }} 
                           onClick={()=>continueHandle('en',false,null)}                         
                           >{continueOkay ? <ScaleLoader width={20} color="#fff" /> : businesses.langSpanish ? 'Continue In English' :'Continue'}</Button><br/>
                        <p
                          style={{
                            color:site.kioskUiTermTextColor ? site.kioskUiTermTextColor : 'rgb(232, 165, 48)',
                            fontSize: site.kioskUiTermTextSize !==undefined && site.kioskUiTermTextSize!==null && site.kioskUiTermTextSize!=='' ? site.kioskUiTermTextSize : '10px',
                            marginBottom:site.kioskUiTabletView !==undefined && site.kioskUiTabletView===true ? "8px":'none',
                            marginRight:site.kioskUiTabletView !==undefined && site.kioskUiTabletView===true ? "20%":'none' ,
                            margin:'auto',
                            textShadow:'black 0px 0px 1px',
                            maxWidth: businesses.langSpanish? '':'400px',
                            textAlign: businesses.langSpanish? 'initial':'left',
                          }}
                        >
                        By pressing Continue, you are signing up to receive automated text alerts and marketing calls from an autodialer system or live person.
                        Consent to receive automated text alerts and marketing calls not required for purchase.
                        Approx 9 msgs/mo. Msg & Data Rates May Apply. Reply HELP for HELP, STOP ALL to cancel.
                        Ts & Cs / Privacy Policy https://client.texnrewards.net/terms_of_use.asp
                        </p>
                        </div>:''}

                      {businesses.langSpanish ?  <div className={businesses.langEnglish? 'continuecontainerright':'mt-20'}>
                        <Button
                        size={'2rem'}
                        disableColor = {site.kioskUiButtonDisabledColor!==undefined && site.kioskUiButtonDisabledColor!==null && site.kioskUiButtonDisabledColor!=='' ? site.kioskUiButtonDisabledColor : 'rgb(136, 136, 136)'}
                        disabled={!mobileIsValid || continueOkay} className='continuebtn' 
                        bg={tearApart || tearApartAll ? "#FFA500"  : !mobileIsValid || continueOkay ? buttonDisabledColor :buttonsucsessColor}
                        color={tearApart || tearApartAll ? "#45444A" : site.kioskUiContinueBtnTextColor!==undefined && site.kioskUiContinueBtnTextColor!==null && site.kioskUiContinueBtnTextColor!=='' ? site.kioskUiContinueBtnTextColor : 'white'}
                        style={{display:site.kioskUiHideContBtn!==undefined && site.kioskUiHideContBtn===true && !mobileIsValid ? 'none' :'block' }} 
                            onClick={()=>continueHandle('esp',false,null)} 
                            >
                         {continueOkay ? <ScaleLoader  width={20} color="#fff" /> : businesses.langEnglish ?  'Continuar en Espanol': 'Continuar'}</Button><br/>
                        <p
                         style={{
                            color:site.kioskUiTermTextColor ? site.kioskUiTermTextColor : 'rgb(232, 165, 48)',
                            fontSize: site.kioskUiTermTextSize !==undefined && site.kioskUiTermTextSize!==null && site.kioskUiTermTextSize!=='' ? site.kioskUiTermTextSize : '10px',
                            marginBottom:site.kioskUiTabletView !==undefined && site.kioskUiTabletView===true ? "8px":'none',
                            marginRight:site.kioskUiTabletView !==undefined && site.kioskUiTabletView===true ? "20%":'none', 
                            margin:'auto',
                            textShadow:'black 0px 0px 1px',
                            maxWidth: businesses.langEnglish? '':'400px',
                            textAlign: businesses.langEnglish? 'initial':'left',
                          }}
                        >
                    Presionando siguiente, te estás inscribiendo para recibir alertas automáticas de texto de un Sistema
                  de marcado automático. El consentimiento para recibir alertas automáticas de texto no es necesario
                  para compra. Aproximadamente 9 mensajes / mes. Se pueden aplicar tarifas de mensajes y datos. Responde
                  a la ayuda por ayuda, deja de cancelar. Ts y Cs / Politica de privacidad
                  https://client.texnrewards.net/terms_of_use.asp
                        </p>
                        </div>:''}
                        

            </div>
        </div>
        </div>
        : <p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>
    )
}